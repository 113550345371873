export enum ERedirectActions {
  BasketChanged = 'bucket_changed',
  EmailChangedInfo = 'email_changed',
  EmailChangedVerify = 'email_changed_verify',
  KycResults = 'kyc_results',
  LimitChangeRequired = 'limit_change_required',
  NewKyc = 'new_kyc',
  NewOffer = 'new_offer',
  NoPositionsInCatalogue = 'no_positions_in_catalogue',
  OfferChanged = 'offer_changed',
  OfferDeleted = 'offer_deleted',
  OfferStatusChanged = 'offer_status_changed',
  PasswordChangeInfo = 'password_change_info',
  PasswordChangeProceed = 'password_change_proceed',
  PasswordRestoreProceed = 'password_restore_proceed',
  PasswordCreateProceed = 'password_create_proceed',
  SignupApplication = 'signup_application',
  SignupConfirm = 'signup_confirm',
  PhoneChanged = 'phone_changed',
  ErpOrder = 'erp_order',
  NewOrder = 'new_order',
  IdpLogin = 'idp_login',
  GodMode = 'god_mode',
  DownloadFile = 'download-file',
  Report = 'report',
  Auth = 'auth',
  GodOrder = 'god_order',
  OfferPolicy = 'offer_policy'
}
